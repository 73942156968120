import './Team.scss';

import tigr from '../../images/pfp/tigr.png';
import deear from '../../images/pfp/deear.png';
import zen from '../../images/pfp/zen.png';

import twitter from '../../images/twitter.svg';

const Team = () => {
  return (
    <>
      <section className="team" id="team">
        <h1>Team</h1>

        <section className="team-members">

          <div className="member">
            <img className="member-pfp" src={tigr}/>
            <p className="member-name">tigr</p>
            <p className="member-info"><span style={{fontWeight: "bold"}}>Developer</span>, contracts, back end, front end. Project socials (Discord, Twitter). Product Design Lead</p>
          </div>

          <div className="member">
            <img className="member-pfp" src={deear}/>
            <p className="member-name">Your Deear</p>
            <p className="member-info"><span style={{fontWeight: "bold"}}>Artist</span>, creator of Dear Deer sprites you can find on chain. Indie game developer</p>
          </div>

          <div className="member">
            <img className="member-pfp" src={zen}/>
            <p className="member-name">Zeneca_33</p>
            <p className="member-info">We are a part of Zeneca's 333 Club through which he is offering advice to us</p>
          </div>

        </section>

      </section>
    </>
  );
};

export default Team;
