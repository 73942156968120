import './MintForm.scss';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import contract from '../../abi/DearDeer.json';
import { useState, useEffect } from 'react';
import Spinner from '../Spinner';

// SETTINGS
import { merkleAPI, contractAddress, infuraId, network } from '../settings';
const mintPrice = 0.05; // ETH

const Web3 = require("web3");
const abi = contract.abi;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId,
    },
  },
};

const web3Modal = new Web3Modal({
  network, 
  cacheProvider: true,
  providerOptions,
  theme: "dark",
});

const MintForm = (props) => {

  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [mintAmount, setMintAmount] = useState(1);
  const [wlStatus, setWlStatus] = useState(null);
  const [proof, setProof] = useState(null);
  const [maxMint, setMaxMint] = useState(null);
  const [deerBalance, setDeerBalance] = useState(null);


  useEffect(() => {

  }, []);


  // CONNECTION AND MINTING HANDLERS
  
  // On "Connect Wallet" button
  async function connectWalletHandler() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);

    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.net.getId();

    handleAccountsChanged(accounts);
    setChainId(chainId);

    provider.on("accountsChanged", accounts => handleAccountsChanged(accounts));
    provider.on("chainChanged", () => window.location.reload());
  }

  // When new account provided
  async function handleAccountsChanged(accounts) {
    if (props.mintPublic === false) {
      await fetchProof(accounts[0]);
    }
    setAccount(accounts[0]);
    fetchMintLimits(accounts[0]);
  }

  // Fetches Merkle proof for given address and set proof and wl state
  async function fetchProof(address) {
    const data = { address: address };
    try {
      const response = await fetch(merkleAPI, {
        method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(response => {
        setProof(response.proof);
        setWlStatus(response.wlStatus);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Check how many deer address owns (mint limit basically)
  const fetchMintLimits = async (account) => {
    const web3 = new Web3(`https://${network}.infura.io/v3/${infuraId}`);
    const contract = await new web3.eth.Contract(abi, contractAddress);
    const balanceOfAccount = await contract.methods.balanceOf(account).call();
    setDeerBalance(balanceOfAccount);
    const maxMintPerWallet = await contract.methods.MAX_MINT_PER_WALLET().call();
    setMaxMint(maxMintPerWallet);
  }

  // SEND MINT TRANSACTION (the most important function actually)
  async function handleMint() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    const contract = await new web3.eth.Contract(abi, contractAddress, { from: account });

    // Set current nonce from wallet
    let nonce = await web3.eth.getTransactionCount(account);

    // Calculate price
    // TODO FETCH PRICE FROM CONTRACT IN THE FUTURE
    const value = web3.utils.toWei(String(mintPrice * mintAmount));

    // These will be set depending on the mintPublic status
    let tx;
    let gas;
    let txParams;

    // IF WL MINT
    if (props.mintPublic === false && wlStatus === true) {

      // Build transaction
      tx = await contract.methods.whiteListMint(mintAmount, proof);

      // Estimate gas
      gas = await contract.methods.whiteListMint(mintAmount, proof).estimateGas({ value });
      gas = gas + 5000 * mintAmount; // just in case

      // Build tx object to send
      txParams = { nonce, to: contractAddress, from: account, gas, data: tx.encodeABI(), value };

    } 

    // IF PUBLIC MINT
    else {
      
      // Build transaction
      tx = await contract.methods.mint(mintAmount);

      // Estimate gas
      gas = await contract.methods.mint(mintAmount).estimateGas({ value });
      gas = gas + 5000 * mintAmount;

      // Build tx object to send
      txParams = {nonce, to: contractAddress, from: account, gas, data: tx.encodeABI(), value};
    }

    // Send transaction
    setTxPending(true);

    let receipt = await tx.send(txParams);

    setTxPending(false);

    handleTxData(receipt);
    fetchMintLimits(account);

  }

  // POPULATES TX DATA
  const [txPending, setTxPending] = useState(null);

  const [txStatus, setTxStatus] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [deerIds, setDeerIds] = useState(null);

  function handleTxData(receipt) {
    if (receipt.status === true) {
      setTxStatus(true);
      setTxHash(receipt.transactionHash);

      let deerIds = [];

      const transfer = receipt.events.Transfer;
      transfer.forEach(e => deerIds.push(e.returnValues.tokenId));

      setDeerIds(deerIds);

    } else {
      setTxStatus(false);
    }
  }


  // BUTTONS AND OTHER UI ELEMENTS

  // Connect Wallet
  const connectButton = () => {
    return (
      <a className="big-button connect-button" onClick={connectWalletHandler}>Connect Wallet</a>
    );
  }

  // The Mint Button
  const mintButton = () => {
    // In case of WL mint and not-whitelisted don't allow mint
    if (props.mintPublic === false && wlStatus !== true) {
      return (
        <a className="big-button mint-button-disabled">Can't mint, no WL</a>
      );
    } else {
    // In case of WL mint and whitelisted allow mint
      if (mintAmount !== 5) {
        return (
          <a className="big-button mint-button" onClick={handleMint}>Mint {deerNumber(mintAmount)}</a>
        );
      }
      return (
        <a className="big-button diamond-mint-button" onClick={handleMint}>Mint {deerNumber(mintAmount)}</a>
      );
    }
  }

  // Returns different deer images for mint button depending on amount
  const deerNumber = (num) => {
    switch (num) {
      case 1:
        return(<><div className="one deer"></div></>);
      case 2:
        return(<><div className="two deer"></div><div className="two deer"></div></>);
      case 3:
        return(<><div className="three deer"></div><div className="three deer"></div><div className="three deer"></div></>);
      case 4:
        return(<><div className="four deer"></div><div className="four deer"></div><div className="four deer"></div><div className="four deer"></div></>);
      case 5:
        return(<><div className="five deer"></div><div className="five deer"></div><div className="five deer"></div><div className="five deer"></div><div className="five deer"></div></>);
      default:
        return(<></>);
    }
  }

  // Amount selector form
  const amountSelector = () => {
    return (
      <div className="amount">
        <a className="count-button" onClick={decreaseAmount}>-</a>
        <span className="count">{mintAmount}</span>
        <a className="count-button" onClick={increaseAmount}>+</a>
      </div>
    );
  }

  // Decrease mint amount
  const decreaseAmount = () => {
    if (mintAmount <= 1) {
      console.log("Already at minimumt");
      setMintAmount(1);
    } else {
      setMintAmount(mintAmount - 1);
    }
  }

  // Increase mint amount
  const increaseAmount = () => {
    if (mintAmount >= 5) {
      console.log("Already at maximum");
      setMintAmount(5);
    } else {
      setMintAmount(mintAmount + 1);
    }
  }

  // Check whether we are connected to Mainnet
  const networkStatus = () => {
    if (chainId !== 1) {
      return (
        <>
          <div className="network-status">
            <p>⚠️ Looks like your wallet is connected to a WRONG NETWORK!</p>
            <button className="switch-to-mainnet" onClick={switchToMainnet}>Switch to Mainnet</button>
          </div>
        </>
      );
    }
  }

  // Request wallet to switch to Mainnet
  const switchToMainnet = async () => {
    const provider = await web3Modal.connect();
    await provider.request({ method: "wallet_switchEthereumChain", params: [{ chainId: "0x1"}]});
  }

  // Renders holder status
  const holderStatus = () => {
    if (txPending === true) {
      return (
        <>
          <Spinner /> 
        </>
      );
    }
    if (txStatus === true && deerIds !== null) {
      const listOfDeers = deerIds.map(id => <p key={id}><a href={`https://opensea.io/assets/${contractAddress}/${id}`}>DEAR DEER #{id}</a></p>);
      return (
        <>
          {mintingLimits()}
          <div className="transaction-status">
            <p>🟢 Transaction successful: <a href={`https://etherscan.io/tx/${txHash}`}>ETHERSCAN</a></p>
            <div className="list-of-deers">
              <h1>YOUR MINTED DEERS ON OPENSEA:</h1>
              {listOfDeers}
            </div>
          </div>
        </>
      );
    } else if (txStatus === false) {
      <>
        {mintingLimits()}
        <div className="transaction-status">
          <p>🔴 Transaction failed: <a href={`https://etherscan.io/tx/${txHash}`}>ETHERSCAN</a></p>
        </div>
      </>
    }
    return (
      <>
        {mintingLimits()}
      </>
    )

  }

  // Renders minting limits
  const mintingLimits = () => {
    return (
      <>
        <div className="minting-limits">
          Max <span className="limit">{maxMint}</span> per wallet, you have <span className="limit">{deerBalance}</span>, can mint <span className="limit">{maxMint - deerBalance}</span>
        </div>
      </>
    );
  }


  // MINT FORM ITSELF

  const mint = () => {

    // IF MINT ONLY WL
    if (props.mintPublic === false) {
      return (
        <>
          <p className="status">
            Your wallet <span className="wallet">{account}</span> is {wlStatus === true ? <span className="whitelisted">WHITELISTED</span> : <span className="not-whitelisted">NOT WHITELISTED</span>}
          </p>
          {amountSelector()}
          {mintButton()}
          {holderStatus()}
          {networkStatus()}
        </>
      );
    } else {

    // IF MINT PUBLIC
      return (
        <>
          <p className="status">
            Connected wallet <span className="wallet">{account}</span>
          </p>
          {amountSelector()}
          {mintButton()}
          {holderStatus()}
          {networkStatus()}
        </>
      );
    }
  }

  // QUICK DEBUG
  const debug = () => {
    return (
      <>
        <p>DEBUG DATA</p>
        <p>Account: {account === null || account === undefined ? 'Not connected' : account.toString()}</p>
        <p>Network: {chainId === null ? 'Not connected' : chainId.toString()}</p>
        <p>WL Status: {wlStatus.toString()}</p>
        <p>Proof Length: {proof.length.toString()}</p>
        <p>Mint Amount: {mintAmount}</p>
      </>
    );
  }

  // MAIN MINT FORM COMPONENT RENDERS

  if (account === null || account === undefined) {
    return (
      <>
        {connectButton()}
        {/* {debug()} */}
      </>
    );
  } else {
    return (
      <>
        {mint()}
        {/* {debug()} */}
      </>
    );
  }
}


export default MintForm;