import './Minter.scss';
import { useState, useEffect } from 'react';

import MintStatus from '../MintStatus';
import MintForm from '../MintForm';

// SETTINGS
import { backendURL } from '../settings';

const Minter = () => {

  const [mintStatusUpdated, setMintStatusUpdated] = useState(false);

  const [mintOn, setMintOn] = useState(false);
  const [mintPublic, setMintPublic] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(5000);

  const fetchRate = 2000;

  const fetchMintStatus = async () => {
    try {
      const response = await fetch(`${backendURL}/mintstatus`)
        .then(response => response.json())
        .then(response => updateMintStatus(response));
    } catch (err) {
      console.log(err);
    }
  }

  const updateMintStatus = (mintStatus) => {
    if (Object.keys(mintStatus).length !== 0) {
      setMintOn(mintStatus.mintOn);
      setMintPublic(mintStatus.mintPublic);
      setTotalSupply(mintStatus.totalSupply);
      setMaxSupply(mintStatus.maxSupply);
      setMintStatusUpdated(true);
    }
  }

  useEffect(() => {
    const timer = setInterval(fetchMintStatus, fetchRate);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <section className="mint" id="mint">

        <h1>MINT <span className="blue">0.05 ETH</span></h1>
        {mintOn === true && totalSupply !== maxSupply ? <MintForm mintPublic={mintPublic}></MintForm> : null}

        <MintStatus
          mintStatusUpdated={mintStatusUpdated}
          mintOn={mintOn}
          mintPublic={mintPublic}
          totalSupply={totalSupply}
          maxSupply={maxSupply}
        />

      </section>
    </>
  );
};

export default Minter;