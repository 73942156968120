import Menu from './components/Menu';
import Header from './components/Header';
import Minter from './components/Minter';
import PngGenerator from './components/PngGenerator';
import SpriteRouter from './components/SpriteRouter';
import About from './components/About';
import Team from './components/Team';
import Links from './components/Links';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <main>
        <Menu />
        <Header />
        <Minter />
        {/* <PngGenerator /> */}
        <About />
        <SpriteRouter />
        <Team />
        {/* <Links /> */}
        <Footer />
      </main>
    </>
  );
};

export default App;