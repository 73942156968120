import './SpriteRouter.scss';

const SpriteRouter = () => {
  return (
    <>
      <section className="sprite-router" id="sprite-router">
        <h1>Sprite Router</h1>
        <p>Web3 is "WE BUILD" and we don't want the 3rd attempt to be ruined. It's crucial to give back. We give back with our <a href="https://etherscan.io/address/0xa140c5Bfa8fAFca49770e23d9da50dbec35B5d9c#readContract">Sprite Router</a>ᵍᵐ.</p>
        <p>All our sprites are fair rasters deployed on chain and are free to use by anyone in any way they want. CC0 License.</p>
        <p>Copy them, modify, integrate into your own projects. You can use these sprites in your onchain NFT projects to reduce deployment costs if they suit your style. They are 72x72 pixels, and if you don't use antlers you can build a vast amount of different characters with them, not just deer, if you add just a bit of your own graphics. We already paid about <span style={{fontWeight: "bold", color: "#f66" }}>8 ETH</span> for their deployment, so you don't have to pay that much :)</p>
        {/* <h2>HOW TO FETCH THESE SPRITES FROM ETHEREUM CHAIN</h2> */}
        <p>How to fetch these sprites from Ethereum chain?</p>
        <p>Use our <a href="https://etherscan.io/address/0xa140c5Bfa8fAFca49770e23d9da50dbec35B5d9c#readContract">Sprite Router</a>ᵍᵐ.</p>
        <p>Implement a simple interface on your contract:</p>
        <p className="code">
          <span style={{color: "#569CD6"}}>interface</span> <span style={{color: "#4EC9B0"}}>ISpriteRouter</span> <span style={{color: "#FFD700"}}>{`{`}</span><br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: "#569CD6"}}>function</span> <span style={{color: "#DCDCAA"}}>getSprite</span><span style={{color: "#DA70D6"}}>(</span><span style={{color: "#4EC9B0"}}>uint8</span> <span style={{color: "#9CDCFE"}}>partId</span>, <span style={{color: "#4EC9B0"}}>uint8</span> <span style={{color: "#9CDCFE"}}>typeId</span>, <span style={{color: "#4EC9B0"}}>uint8</span> <span style={{color: "#9CDCFE"}}>spriteId</span><span style={{color: "#DA70D6"}}>)</span> <span style={{color: "#569CD6"}}>external view</span> <span style={{color: "#C586C0"}}>returns</span> <span style={{color: "#DA70D6"}}>(</span><span style={{color: "#4EC9B0"}}>string</span> <span style={{color: "#569CD6"}}>memory</span><span style={{color: "#DA70D6"}}>)</span>;<br/>
          <span>{`}`}</span><br/>
          ISpriteRouter spriteRouter;
        </p>
        <p>Initialize it in a constructor or in a setter function:</p>
        <p className="code">
          spriteRouter = <span style={{color: "#DCDCAA"}}>ISpriteRouter</span><span style={{color: "#87CEFA"}}>(</span><span style={{color: "#B5CEA8"}}>0xa140c5Bfa8fAFca49770e23d9da50dbec35B5d9c</span><span style={{color: "#87CEFA"}}>)</span>;
        </p>
        <p>Use its <span style={{color: "#DCDCAA"}}>getSprite</span> function with three uint8 parameters to get your sprite according to the scheme in our published <a href="https://www.figma.com/community/file/1057790039294087829">Figma File</a>.</p>
        <p>It will come in form of base64 encoded PNG with resolution of 72x72 px.</p>
        <p>Don't forget to add <span style={{color: "#DCDCAA"}}>data:image/png;base64,</span> in front of the resulting string.</p>
        <p>Detailed info in this <a href="https://www.figma.com/community/file/1057790039294087829">Figma Community File</a> or this <a href="https://www.figma.com/file/9vRGhl1VDUT6h57c1WQRhE/Dear-Deer-%E1%B5%8D%E1%B5%90---Onchain-Sprite-Router?node-id=0%3A1">Figma Shared File</a> if you don't want to sign up on Figma. Give it a moment to load all those rasters we have there.</p>
      </section>
    </>
  );
};

export default SpriteRouter;