import './Links.scss';
import discord from '../../images/discord.svg';
import twitter from '../../images/twitter.svg';
import looksrare from '../../images/looksrare.svg';
import opensea from '../../images/opensea.svg';
import etherscan from '../../images/etherscan.svg';

const Links = () => {
  return (
    <>
      <section className="links">
          <div className="link-list">
              <a href="https://discord.gg/CEJjrgJ68s">
                <img src={discord} alt="discord logo" className="discord"/>
              </a>
              <a href="https://twitter.com/DearDeerNFT">
                <img src={twitter} alt="twitter logo" className="twitter"/>
              </a>
              <a href="https://looksrare.org/collections/0x108578c96C61f3e5a3a12d43d42A60346a6Bfcb2">
                <img src={looksrare} alt="looksrare logo" className="looksrare"/>
              </a>
              <a href="https://opensea.io/collection/dear-deer-nft">
                <img src={opensea} alt="opensea logo" className="opensea"/>
              </a>
              <a href="https://etherscan.io/address/0x108578c96c61f3e5a3a12d43d42a60346a6bfcb2#code">
                <img src={etherscan} alt="etherscan logo" className="etherscan"/>
              </a>
          </div>
      </section>
    </>
  );
};

export default Links;