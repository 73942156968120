import './MintStatus.scss';
import Spinner from '../Spinner';


const MintStatus = (props) => {
  if (props.mintStatusUpdated) {
    return (
      <>
        <section className="mintStatus">
          <h1 className="total-minted">
            {props.totalSupply} minted
          </h1>

          <MintOnAndPublic
            mintOn={props.mintOn}
            mintPublic={props.mintPublic}
            totalSupply={props.totalSupply}
            maxSupply={props.maxSupply}
          />

        </section>
      </>
    );
  } else {
    return (
      <>
        <section className="mintStatus">
          <h2 className="fetching">Fetching mint status<Spinner /></h2>
        </section>
      </>
    )
  }
};


const MintOnAndPublic = (props) => {

  // If minting not started
  if (props.mintOn === false && props.totalSupply !== props.maxSupply) {
    return( // Mint is off
      <>
        <p>Minting is now paused, check <a href="https://discord.gg/deardeer">discord</a> for updates!</p>
      </>
    );
  }

  // If minting started and not ended
  if (props.mintOn === true && props.totalSupply !== props.maxSupply) {

    // If only WL
    if (props.mintPublic === false) {
      return(
        <>
          <p className="open">Mint is WL only now, check <a href="https://discord.gg/deardeer">discord</a> for details</p>
        </>
      );
    }

    // If public
    else if (props.mintPublic === true) {
      return(
        <>
          <p className="open">Public mint is open</p>
        </>
      );
    }
  }

  // If all minted
  else if (props.totalSupply === props.maxSupply) {
    return(
      <>
        <p>All dear deers minted. Find yours on <a href="https://opensea.io/collection/dear-deer-nft">Opensea</a>!</p>
      </>
    )
  }
};


export default MintStatus;