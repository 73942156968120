import './About.scss';
import deerPreview from '../../images/deer_preview.gif';

const About = () => {
  return (
    <>
      <section className="about" id="project-features">
        <h1>Project Features</h1>
        <section className="features">
          <div className="preview"><img src={deerPreview} alt="Animated preview of different deers"></img></div>
          <div className="feature-list">
            <ul>
              <li>Generation <span className="red">at the time of mint</span></li>
              <li><span className="pink">Fully onchain</span><br/>No IPFS or API involved, both graphics and metadata are stored on <span className="blue">Ethereum</span></li>
              <li>Over <span className="lime">600 unique sprites</span> in 15+ trait categories</li>
              <li><span className="green">Tech and art focused</span> with fanatical dev and artist</li>
              <li>Meaning <span className="yellow">no low effort</span> copy-pasting of other contracts/art<br/><span className="yellow">Genuine and original</span></li>
              <li><span className="violet">Community focused</span>, DAO-like structure from the beginning</li>
              <li>Meaning <span className="pink">no low effort</span> copy-paste of non-deliverable roadmaps<br/><span className="darkBlue">We build together, join us dear</span></li>
            </ul>
          </div>
        </section>
      </section>
    </>
  );
};

export default About;