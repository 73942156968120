import './Footer.scss';

import Links from '../Links';

const Footer = () => {
  return (
    <>
      <section className="footer" id="header">
        <Links />
      </section>
    </>
  );
}

export default Footer;