// MAINNET SETTINGS
export const backendURL = "https://deer-merkle-backend-bzv53.ondigitalocean.app"
export const merkleAPI = 'https://deer-merkle-backend-bzv53.ondigitalocean.app/merkle';
export const contractAddress = "0x108578c96C61f3e5a3a12d43d42A60346a6Bfcb2"; // MAINNET
export const infuraId = "03ebdf791e6a481c932f5e475d6b511e";
export const network = "mainnet";

// RINKEBY SETTINGS
// export const backendURL = "https://deer-merkle-backend-bzv53.ondigitalocean.app"
// export const merkleAPI = 'https://deer-merkle-backend-bzv53.ondigitalocean.app/merkle';
// export const contractAddress = "0x3dD575F88E635cFe16baa479665A4C57a68cfF3E"; // Rinkeby
// export const infuraId = "03ebdf791e6a481c932f5e475d6b511e";
// export const network = "rinkeby";