import './Header.scss';
import './Glitch.scss';

const Header = () => {
  return (
    <>
      <section className="hero" id="header">
        <div className="logo"></div>
        <h1>DΞΛR DΞΞR</h1>
      </section>
      <section className="tagline">
        <p>
          Hardcore&nbsp;<span className="pink">onchain</span> collection<br/>of&nbsp;pixel&nbsp;art generated&nbsp;<span className="yellow" style={{fontFamily: 'Manrope', fontSize: "1.25em", display: "inline-block", transform: "rotate(-5deg)"}}><span className="glitch" data-text="DΞΞR🦌">DΞΞR🦌</span></span>
        </p> 
      </section>
    </>
  );
}

export default Header;