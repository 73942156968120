import { useState, useEffect } from 'react';
import './Menu.scss';

import discord from '../../images/discord.svg';
import twitter from '../../images/twitter.svg';
import looksrare from '../../images/looksrare.svg';
import opensea from '../../images/opensea.svg';
import etherscan from '../../images/etherscan.svg';

import logo from '../../images/deer_logo.png';

const scrollHandler = (e) => {
  e.preventDefault();
  const anchorId = e.target.attributes.href.nodeValue;
  const anchor = document.querySelector(anchorId);
  anchor && anchor.scrollIntoView({ behavior: "smooth", block: "start" });
}

const scrollTop = (e) => {
  e.preventDefault();
  window.scrollTo({ top: 0, behavior: "smooth"});
}

const Menu = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', toggleMenuStyle, {passive: true});
    return () => {
      window.removeEventListener('scroll', toggleMenuStyle);
    }
  }, []);

  const toggleMenuStyle = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }

  return (
    <>
      <section className="menu" style={scrollPosition >= 288 ? {borderBottom: "1px #444 solid"} : {}}>
        <a href="#header" onClick={scrollTop}><img src={logo} alt="dear deer logo" className="menuLogo" onClick={scrollTop}/></a>
        <div className="menuItems">
          <ul>
            <li><a href="#mint" onClick={scrollHandler}>Mint</a></li>
            {/* <li><a href="#png-generator" onClick={scrollHandler}>PNG Generator</a></li> */}
            <li><a href="#project-features" onClick={scrollHandler}>Project Features</a></li>
            <li><a href="#sprite-router" onClick={scrollHandler}>Sprite Router</a></li>
            <li><a href="#team" onClick={scrollHandler}>Team</a></li>
          </ul>
        </div>
        <div className="menuLinks">
          <a href="https://discord.gg/CEJjrgJ68s"><img src={discord} alt="discord logo" className="discord" /></a>
          <a href="https://twitter.com/DearDeerNFT"><img src={twitter} alt="twitter logo" className="twitter" /></a>
          <a href="https://looksrare.org/collections/0x108578c96C61f3e5a3a12d43d42A60346a6Bfcb2"><img src={looksrare} alt="looksrare logo" className="looksrare" /></a>
          <a href="https://opensea.io/collection/dear-deer-nft"><img src={opensea} alt="opensea logo" className="opensea" /></a>
          <a href="https://etherscan.io/address/0x108578c96c61f3e5a3a12d43d42a60346a6bfcb2#code"><img src={etherscan} alt="etherscan logo" className="etherscan" /></a>
        </div>
      </section>
    </>
  );
}

export default Menu;